<template>
  <v-container>
    <v-toolbar
      v-if="!hideToolbar"
      dark
      color="primary"
      style="flex: 0; margin-bottom: 10"
    >
      <v-row align="center">
        <v-col class="text-left">
          <v-icon class="white--text ml-2" x-large @click="goBackCryo">
            mdi-arrow-left
          </v-icon>
        </v-col>
        <v-spacer />
        <v-col>
          <v-toolbar-title
            class="text-center"
            style="font-size: 32px !important; font-weight: 500 !important"
          >
            {{
              ($route.params.category != 'sperm'
                ? $route.params.category + 's'
                : $route.params.category
              ).toUpperCase()
            }}
          </v-toolbar-title>
        </v-col>
        <v-spacer />
        <v-col />
      </v-row>
    </v-toolbar>
    <v-row style="margin-top: 10px">
      <v-col cols="3">
        <FreezeCard
          :title="
            $route.params.category != 'sperm'
              ? $route.params.category + 's'
              : $route.params.category
          "
          :total="total"
          :showArrow="showArrow"
          :style_custom="style_custom"
        ></FreezeCard>
        <div class="d-flex flex-column pa-4 mt-auto">
          <v-btn
            block
            rounded
            :disabled="disableInitiateTransport"
            color="primary"
            @click="openInitateTransport"
          >
            Initiate Transport
          </v-btn>
          <v-btn
            block
            rounded
            :disabled="disableDiscontinueService"
            class="mt-3"
            color="error dark-1"
            @click="openDiscontinueService"
          >
            Discontinue Service
          </v-btn>
        </div>
      </v-col>
      <v-col cols="9">
        <v-card
          class="mx-auto elevation-0 d-flex"
          style="background-color: #eaeef2; justify-content: space-between"
        >
          <v-card-text
            class="white--text d-flex flex-column align-center justify-center rounded-l-lg"
            style="background-color: #1f6070; width: 280px"
            height="200px"
          >
            <v-card-title
              class="text-center justify-center"
              style="padding: 0px; font-size: 26px; font-weight: 500"
            >
              $ {{ balance ? balance.toFixed(2) : Number(0).toFixed(2) }}
            </v-card-title>
            <p class="subtitle-1 mb-0" style="padding: 0px">
              {{
                ($route.params.category != 'sperm'
                  ? $route.params.category + 's'
                  : $route.params.category
                ).toUpperCase()
              }}
              BALANCE
            </p>
          </v-card-text>
          <v-card-text
            class="d-flex justify-space-between align-center"
            height="200px"
          >
            <div class="d-flex flex-column">
              <p
                style="font-size: 18px !important; font-weight: 500 !important"
                class="mb-0"
                v-if="next_billing_at"
              >
                Next due date: {{ next_billing_at }} {{ method ? method : '' }}
              </p>
              <p
                v-else
                style="font-size: 18px !important; font-weight: 500 !important"
                class="mb-0"
              >
                No outstanding invoices
              </p>
            </div>
            <div class="d-flex mt-4 align-center">
              <v-btn
                small
                rounded
                style="height: 35px; margin-bottom: 15px"
                color="green darken-2 white--text"
                @click="payAllInvoice(invoicesItems[0])"
                :disabled="!balance || balance < 0"
              >
                Pay Now
              </v-btn>
              <v-btn
                small
                rounded
                class="ml-3"
                color="primary"
                dark
                style="height: 35px; margin-bottom: 15px"
                @click="dialog = !dialog"
              >
                Manage Payment Methods
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
        <v-card tile class="px-6 py-2 rounded mt-8" style="width: 100%">
          <v-card flat style="width: 100%">
            <v-card light flat>
              <template>
                <v-tabs v-model="tab">
                  <v-tab key="freeze">Freezes</v-tab>
                  <v-tab key="invoices">Invoices</v-tab>

                  <v-tabs-items v-model="tab" style="width: 100%">
                    <v-tab-item key="freeze">
                      <v-card-text>
                        <v-data-table
                          :headers="freezeHeaders"
                          :items="freezeItems"
                          :loading="freezeLoading"
                        >
                          <template v-slot:[`item.FreezeDate`]="{ item }">
                            {{ item.FreezeDate }}
                          </template>
                          <template v-slot:[`item.InventoryDate`]="{ item }">
                            {{ item.InventoryDate }}
                          </template>
                          <template v-slot:[`item.source`]="{ item }">
                            {{ item.source }}
                          </template>
                          <template v-slot:[`item.type`]="{ item }">
                            {{ item.type }}
                          </template>
                          <template v-slot:[`item.location`]="{ item }">
                            {{ item.location }}
                          </template>
                          <template v-slot:[`item.next_billing_at`]="{ item }">
                            {{ item.next_billing_at }}
                          </template>
                          <template v-slot:[`item.billingprofile`]="{ item }">
                            {{ item.billingprofile }}
                          </template>
                          <template v-slot:[`item.amount`]="{ item }">
                            {{ item.amount }}
                          </template>
                          <template v-slot:[`item.active_pbp`]="{ item }">
                            <v-badge
                              inline
                              :color="item.active_pbp ? 'green' : 'red'"
                            ></v-badge>
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </v-tab-item>
                    <v-tab-item key="invoices">
                      <v-card-text>
                        <v-data-table
                          :headers="invoicesHeaders"
                          :items="invoicesItems"
                          :loading="invoicesLoading"
                        >
                          <template v-slot:[`item.invoice`]="{ item }">
                            {{ item.invoice }}
                          </template>
                          <template v-slot:[`item.details`]="{ item }">
                            {{ item.details }}
                          </template>
                          <template v-slot:[`item.invoicedAmount`]="{ item }">
                            $
                            {{
                              item.invoicedAmount
                                ? item.invoicedAmount.toFixed(2)
                                : 0.0
                            }}
                          </template>
                          <template v-slot:[`item.balancedue`]="{ item }">
                            $
                            {{
                              item.balancedue ? item.balancedue.toFixed(2) : 0.0
                            }}
                          </template>
                          <template v-slot:[`item.paid`]="{ item }">
                            $ {{ item.paid ? item.paid.toFixed(2) : 0.0 }}
                          </template>
                          <template v-slot:[`item.method`]="{ item }">
                            {{ item.duedate }}
                          </template>
                          <template v-slot:[`item.type`]="{ item }">
                            {{ item.type }}
                          </template>
                          <template v-slot:[`item.status`]="{ item }">
                            {{ item.status ? item.status.toUpperCase() : '' }}
                          </template>

                          <template v-slot:[`item.option`]="{ item }">
                            <v-btn
                              color="green white--text elevation-0"
                              @click="payInvoice(item)"
                              :loading="invoicesLoading"
                              :disabled="item.option"
                            >
                              Pay now
                            </v-btn>
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </v-tab-item>
                  </v-tabs-items>
                </v-tabs>
              </template>
            </v-card>

            <v-dialog width="50%" scrollable v-model="dialog">
              <Manage
                @goBack="dialog = !dialog"
                :cards="cards"
                v-on="$listeners"
              />
            </v-dialog>
          </v-card>

          <PayInvoice
            @sucessPayment="reloadAll"
            ref="payInvoice"
            :cards="cards"
            :invoices="selectedInvoices"
            :invoice="selectedInvoice"
          />
          <InitiateTransport
            @submit="requestInitateTransport"
            :loading="initiateTransportLoading"
            ref="initiateTransport"
          />
          <DiscontinueService
            @submit="requestDiscontinueService"
            :loading="descontinueServiceLoading"
            ref="descontinueService"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { api } from '../../sharedPlugin';

import Manage from '@/components/cryo/Manage.vue';
import InitiateTransport from '@/components/cryo/InitiateTransport.vue';
import DiscontinueService from '@/components/cryo/DiscontinueService.vue';
import PayInvoice from '@/components/cryo/PayInvoice.vue';
import FreezeCard from '@/components/FreezeCard.vue';
import _ from 'lodash';
import moment from 'moment';

export default {
  components: {
    Manage,
    PayInvoice,
    FreezeCard,
    InitiateTransport,
    DiscontinueService
  },
  props: ['cards'],
  data() {
    return {
      style_custom: {
        width: '90%'
      },
      tab: 0,
      loadingEnrolled: false,
      submitting: false,
      showArrow: false,
      disableInitiateTransport: true,
      disableDiscontinueService: true,
      next_billing_at: null,
      method: null,
      card: {
        profileid: 0,
        account: '',
        cvv: '',
        expiry: '',
        zipcode: ''
      },
      headersCategory: [
        {
          text: 'Renewal',
          align: 'start',
          value: 'renewal',
          sortable: false
        },
        { text: 'Amount', value: 'amount', sortable: false },
        { text: 'Status', value: 'status', sortable: false }
      ],
      itemsCategory: [],
      loadingCategory: false,
      overlay: false,
      dialog: false,
      invoicesHeaders: [
        { text: 'Invoice#', value: 'invoice', sortable: false },
        { text: 'Details', value: 'details', sortable: false },
        { text: 'Invoiced Amount', value: 'invoicedAmount', sortable: false },
        { text: 'Paid', value: 'paid', sortable: false },
        { text: 'Due Date', value: 'dueDate', sortable: false },
        { text: 'Type', value: 'type', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Options', value: 'option', sortable: false, align: 'center' }
      ],
      invoicesLoading: false,
      invoicesItems: [],
      freezeHeaders: [
        { text: 'Freeze Date', value: 'FreezeDate', sortable: false },
        { text: 'Recieve date', value: 'InventoryDate', sortable: false },
        { text: 'Source', value: 'source', sortable: false },
        { text: 'Type', value: 'type', sortable: false },
        { text: 'Location', value: 'location', sortable: false },
        { text: 'Next Bill Date', value: 'next_billing_at', sortable: false },
        { text: 'Billing Profile', value: 'billingprofile', sortable: false },
        { text: 'Price', value: 'amount', sortable: false },
        {
          text: 'Active',
          value: 'active_pbp',
          sortable: false,
          align: 'center'
        }
      ],
      freezeLoading: false,
      freezeItems: [],
      transactionsLoading: false,
      transactionsItems: [],
      selectedInvoice: null,
      payInoviceModal: false,
      balanceLoading: false,
      balance: 0,
      selectedFreeze: null,
      descontinueServiceModal: false,
      descontinueServiceLoading: false,
      initiateTransportModal: false,
      initiateTranportLoading: false,
      total: 0,
      selectedInvoices: null,
      totalsFreeze: {
        embryo: 0,
        sperm: 0,
        eggs: 0,
        other: 0
      }
    };
  },
  computed: {},
  methods: {
    goBackCryo() {
      this.$router.push({ path: '/cryo' });
    },
    reloadAll() {
      this.getInventory();
      this.getInvoices();
    },
    getDate(date) {
      date = date.split('T')[0];
      date = date.split('-');
      var month = date[1];
      var day = date[2];
      var year = date[0];

      return `${month}/${day}/${year}`;
    },
    reduceObject(value, key) {
      return value
        .map((item) => {
          return parseFloat(item[key]);
        })
        .reduce(
          (previous_value, current_value) => previous_value + current_value
        );
    },
    async getInvoices() {
      this.invoicesLoading = true;

      await api.Cryo.getCryoInvoices(this.$route.params.category)
        .then((res) => {
          this.invoicesItems = res.map((item) => ({
            invoice: item.id,
            details: item.description,
            invoicedAmount: item.total,
            paid: item.paid,
            balancedue: item.balancedue,
            dueDate: item.duedate,
            status: item.status.toUpperCase(),
            type: item.type,
            option:
              item.status.toUpperCase() === 'OPEN' ||
              item.status.toUpperCase() === 'PAST DUE'
                ? false
                : true
          }));

          if (this.invoicesItems.length > 0) {
            this.getBalance();
          }
        })
        .finally(() => (this.invoicesLoading = false));
    },
    async getInventory() {
      this.loadingCategory = true;
      await api.Cryo.getCryoInventory()
        .then((res) => {
          this.getFreezes(res);

          const result = res;

          const { Embryo, Sperm, Egg } = _.groupBy(result, 'type');

          const other = result.filter((item) => {
            return !['Embryo', 'Sperm', 'Egg'].includes(item.type);
          });

          const key = 'total';
          this.totalsFreeze = {
            embryo: Embryo ? this.reduceObject(Embryo, key) : 0,
            sperm: Sperm ? this.reduceObject(Sperm, key) : 0,
            eggs: Egg ? this.reduceObject(Egg, key) : 0,
            other: other.length ? this.reduceObject(other, key) : 0
          };

          this.$route.params.category === 'embryo'
            ? (this.total = this.totalsFreeze.embryo)
            : this.$route.params.category === 'sperm'
            ? (this.total = this.totalsFreeze.sperm)
            : this.$route.params.category === 'egg'
            ? (this.total = this.totalsFreeze.eggs)
            : (this.total = this.totalsFreeze.other);

          if (this.total > 0) {
            this.disableInitiateTransport = false;
            this.disableDiscontinueService = false;
          }
        })
        .finally(() => (this.loadingCategory = false));
    },
    async getFreezes(res) {
      this.freezeLoading = true;

      const result = res.map((item) => ({
        FreezeDate: item.FreezeDate
          ? moment.utc(item.FreezeDate).format('MM/DD/YYYY')
          : '',
        InventoryDate: item.InventoryDate
          ? moment.utc(item.InventoryDate).format('MM/DD/YYYY')
          : '',
        source: item.source,
        type: item.type,
        next_billing_at: item.next_billing_at
          ? moment.utc(item.next_billing_at).format('MM/DD/YYYY')
          : '',
        billingprofile: item.billingprofile,
        amount:
          item.amount && item.discount_amount
            ? `$ ${(
                parseFloat(item.amount) - parseFloat(item.discount_amount)
              ).toFixed(2)}`
            : item.amount
            ? `$ ${item.amount}`
            : `$ 0.00`,
        active_pbp: item.active_pbp,
        location: item.location
      }));

      const { Embryo, Sperm, Egg } = _.groupBy(result, 'type');

      this.$route.params.category === 'embryo'
        ? (this.freezeItems = Embryo)
        : this.$route.params.category === 'sperm'
        ? (this.freezeItems = Sperm)
        : this.$route.params.category === 'egg'
        ? (this.freezeItems = Egg)
        : (this.freezeItems = []);

      this.freezeLoading = false;
    },
    async getNextPayment() {
      this.nextPaymentLoading = true;
      await api.CardConnect.getCryoRecurringPayments(
        this.$route.params.category
      )
        .then((res) => {
          if (res.length > 0) {
            this.nextPayment = res.map((payment) => ({
              date: payment.payment_date
                ? moment.utc(payment.payment_date).format('MM/DD/YYYY')
                : '',
              amount: payment.amount
            }))[0];
          }
        })
        .finally(() => (this.nextPaymentLoading = false));
    },
    async getBalance() {
      this.balanceLoading = true;
      await api.Cryo.getCryoBalance(this.$route.params.category)
        .then((res) => {
          if (res.length > 0) {
            this.balance = res[0]?.balance;
            this.next_billing_at = res[0]?.next_billing_at
              ? moment.utc(res[0]?.next_billing_at).format('MM/DD/YYYY')
              : null;
            this.method =
              res[0]?.method && res[0]?.method == 'Payment Plan'
                ? '(' + res[0]?.method + ')'
                : null;
          }
        })
        .finally(() => (this.balanceLoading = false));
    },
    openInitateTransport() {
      this.$refs.initiateTransport.show();
      this.initiateTransportModal = true;
    },
    async requestInitateTransport() {
      this.initiateTranportLoading = true;
      await api.Cryo.initiateTransport(this.$route.params.category)
        .then(() => {
          this.$store.commit('showSnackbar', {
            message: 'Initiate transport requested.',
            color: 'success'
          });
          this.initiateTransportModal = false;
        })
        .finally(() => (this.initiateTranportLoading = false));
    },
    openDiscontinueService() {
      this.$refs.descontinueService.show();
      this.descontinueServiceModal = true;
    },
    async requestDiscontinueService() {
      this.descontinueServiceLoading = true;
      await api.Cryo.descontinueService(this.$route.params.category)
        .then(() => {
          this.$store.commit('showSnackbar', {
            message: 'Discontinue service requested.',
            color: 'success'
          });
          this.descontinueServiceModal = false;
        })
        .finally(() => (this.descontinueServiceLoading = false));
    },
    payAllInvoice(item) {
      this.selectedInvoices = this.invoicesItems.filter((item) => {
        return item.option === false;
      });

      this.selectedInvoice = item;
      this.selectedInvoice.amount = this.balance;

      this.$refs.payInvoice.show();
    },
    payInvoice(item) {
      this.selectedInvoices = null;
      item.amount = item.balancedue;
      this.selectedInvoice = item;

      this.$refs.payInvoice.show();
    }
  },
  created() {
    this.getInventory();
    this.getInvoices();
    this.getBalance();
  }
};
</script>

<style>
.table-freezes .v-data-table__wrapper {
  background-color: #eaeef2;
  width: 100%;
}
.table-freezes .v-data-table__wrapper table tbody tr td {
  height: 40px !important;
  font-size: 0.8rem !important;
}
.table-freezes .v-data-table__wrapper .v-data-table-header tr th {
  color: #000000 !important;
}
</style>
