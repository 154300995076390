<template>
  <v-card class="rounded-lg" :to="to" :style="style_custom">
    <v-card-title class="py-0 headline default--card-title primary white--text">
      <v-row>
        <v-col cols="12" class="d-flex align-center justify-space-between">
          <p
            style="font-size: 16px !important; font-weight: 500 !important"
            class="mb-0"
          >
            {{ title ? title.toUpperCase() : '' }}
          </p>

          <v-badge
            class="ml-4"
            inline
            :color="type && type.active_pbp ? 'green' : 'red'"
          ></v-badge>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <div class="ma-4 mt-0 mb-0 text-left">
        <v-row style="margin-top: 10px; color: #2f5677">
          <span class="ml-2 text-left" style="color: #2f5677">
            {{ type.billingprofile ? type.billingprofile : '-' }}
          </span>
        </v-row>
        <v-row>
          <span class="ml-2 text-left" style="color: #2f5677">
            Storage Fees:
          </span>
          <span class="ml-2" style="color: #2f5677">
            {{ type.amount ? type.amount : '' }}
          </span>
        </v-row>
        <v-row>
          <span class="ml-2 text-left" style="color: #2f5677">Renew On:</span>
          <span class="ml-2" style="color: #2f5677">
            {{ type.next_billing_at ? type.next_billing_at : '' }}
          </span>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'FreezeCard',
  props: ['title', 'to', 'total', 'showArrow', 'style_custom', 'type'],
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped>
.topbar-fixed {
  position: relative;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}
table tbody tr td {
  height: 20px !important;
}
</style>
